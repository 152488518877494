import {useEffect, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {useDispatch} from "react-redux";
import { setAddThemeActions,setListThemes, setThemesToDelete} from "../store/themeActions";
import {Form , message} from "antd";
import {useTranslation} from "react-i18next";
import { GraphQLFetchData } from "./graphQLFetchData";
import { useNavigate } from 'react-router-dom';
import { setDeleteLoading } from "../../canal/store/canalActions";

export const Hooks = () =>{
    const {GetListThemes, deleteTheme, getUrlsThemes} = GraphQLFetchData()
    const [visible , setVisible] = useState<any>(false)
    const [visibleDeletePopup , setVisibleDeletePopup] = useState<any>(false)

    const [visibleExport , setVisibleExport] = useState<any>(false)
    const [linksExport , setLinksExport] = useState<any>(false)
    const [statusSuccessMessages , setStatusSuccessMessages]=useState<any>(true)
    const [statusErrorMessages , setStatusErrorMessages] = useState<any>(true)
    const dispatch = useDispatch()
    const [formAddTheme]=Form.useForm()
    const navigate = useNavigate();
    const {t} = useTranslation();

    // use Selector redux
    const values = useAppSelector((state:any) => state.ThemeReducer.addThemeConstraintsData)
    const themes = useAppSelector((state) => state.ThemeReducer.themes)
    const loading = useAppSelector((state) => state.ThemeReducer.loading)

    useEffect(()=>{
        formAddTheme.setFieldsValue({
            Title:values.Title,
            classifiedBy:values.classifiedBy,
            channels:values.WebtvChannelIDs,
            Parent_id:values.Parent_id,
            description:values.description,
            visible:values.Visible,
        })
    },[])

  const deletemultipleThemes = async(Ids:any) =>{
   dispatch(setThemesToDelete(Ids))
  }

    const onDeleteTheme = async(Id:any) => {
    dispatch(setDeleteLoading(true)) 
    const idsToremove =  Id.length? Array.from(Id) :[Id]             
        await deleteTheme({variables:{id:idsToremove}}).then((res)=> {message.success(t("classement.Theme deleted successfully"));
        let filteredthemes=Id.length? {...themes,'totalCount':themes.totalCount-Array.from(Id).length,'edges':themes.edges.filter(({node} : any)=>!Array.from(Id).includes(node.Id))} : {...themes,'totalCount':themes.totalCount-1,'edges':themes.edges.filter(({node} : any)=>node.Id!=Id)}
         dispatch(setListThemes(filteredthemes)
        )
        dispatch(setDeleteLoading(false)) 
        }
    ).catch((e)=> message.error("il y a un erreur s'est produit ")
    )  
         
}
     const getThemesLink= async(Id : any)=> {
        await getUrlsThemes({variables:{id:Id}}).then((res)=>{ setLinksExport(res?.data?.ThemeLinks? res?.data?.ThemeLinks : []); }).catch()

     }
    /////***************** show modal details functions **************////////
    const showModal = () =>{
        setVisible(true)
    }
    const showModalDeletePopup = () =>{
        setVisibleDeletePopup(true)
    }
    const handleCancel = () =>{
        setVisible(false)
    }
    const handleCancelDeletePopup = () =>{
        setVisibleDeletePopup(false)
    }
    const handleAddTheme = () => {
        window.parent.postMessage({
            info:"redirect_theme",
            value:"add_menuTitle"
        }, "*")
        navigate("/classement/theme/id/addTheme", {replace: false})
    }
    const getThemesList = async(data:object) => {
        await GetListThemes(data)
       }    
    ///////***************** Form functions *************////////
    const onResetTheme =async () => {
        dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "Title",
            addThemeConstraintsDataValueChange: ""
        }));
        dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "WebtvChannelIDs",
            addThemeConstraintsDataValueChange: []
        }));
         dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "Parent_id",
            addThemeConstraintsDataValueChange: ""
        }));
         dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "description",
            addThemeConstraintsDataValueChange: ""
        }));
         dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "Visible",
            addThemeConstraintsDataValueChange: true
        }));
         dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "Orderr",
            addThemeConstraintsDataValueChange: 0
        }));
        dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "classifiedBy",
            addThemeConstraintsDataValueChange: "title"
        }));
        dispatch(setAddThemeActions({
            addThemeConstraintsDataNameChange: "Logo",
            addThemeConstraintsDataValueChange: ""
        }));
    }
    const onValueFormUserchangeTheme = async(val: any,field : any) => {
     dispatch(setAddThemeActions({
        addThemeConstraintsDataNameChange: field,
        addThemeConstraintsDataValueChange: val
    }));}
    ///**************** show modal export functions *************/////
    const handleShowExportModal = async(Id : any) => {
        await getThemesLink(Id)
      
    }
    const handleCancelModalExport = () => {
        setVisibleExport(false)
    }
    const CopyUrlParticipant = async () =>{
        if ((document.getElementById("myUrlParticipant" ) as HTMLFormElement).value === "") {
            await setStatusErrorMessages(false)
            return (
                statusErrorMessages && message.error({content: t('classement.Thisfieldisempty'), duration: 2}).then(
                    async () => {
                        setStatusErrorMessages(true)
                    }
                )
            )
        } else {

            (document.getElementById("myUrlParticipant") as HTMLFormElement).select();
            document.execCommand("Copy");
            await setStatusSuccessMessages(false)
            statusSuccessMessages && await message.success({content: t('classement.thisfieldissuccessfullycopied'), duration: 2}).then(
                async () => {
                    setStatusSuccessMessages(true)
                }
            );
        }
    }
    const CopyUrlAuditeur = async () => {
        if ((document.getElementById("myUrlAuditeur" ) as HTMLFormElement).value === "") {
            await setStatusErrorMessages(false)
            return (
                statusErrorMessages && message.error({content: t('classement.Thisfieldisempty'), duration: 2}).then(
                    async () => {
                        setStatusErrorMessages(true)
                    }
                )
            )
        } else {

            (document.getElementById("myUrlAuditeur") as HTMLFormElement).select();
            document.execCommand("Copy");
            await setStatusSuccessMessages(false)
            statusSuccessMessages && await message.success({content: t('classement.thisfieldissuccessfullycopied'), duration: 2}).then(
                async () => {
                    setStatusSuccessMessages(true)
                }
            );
        }
    }
    const CopyLienIntegration = async () => {
        if ((document.getElementById("myLienIntegration" ) as HTMLFormElement).value === "") {
            await setStatusErrorMessages(false)
            return (
                statusErrorMessages && message.error({content: t('classement.Thisfieldisempty'), duration: 2}).then(
                    async () => {
                        setStatusErrorMessages(true)
                    }
                )
            )
        } else {

            (document.getElementById("myLienIntegration") as HTMLFormElement).select();
            document.execCommand("Copy");
            await setStatusSuccessMessages(false)
            statusSuccessMessages && await message.success({content: t('classement.thisfieldissuccessfullycopied'), duration: 2}).then(
                async () => {
                    setStatusSuccessMessages(true)
                }
            );
        }
    }
    return({
        deletemultipleThemes,
        visible,
        getThemesLink,
        linksExport,
        onDeleteTheme,
        getThemesList,
        showModal,
        handleCancel,
        handleAddTheme,
        onResetTheme,
        formAddTheme,
        onValueFormUserchangeTheme,
        handleShowExportModal,
        visibleExport,
        handleCancelModalExport,
        CopyUrlParticipant,
        CopyUrlAuditeur,
        CopyLienIntegration,
        values,
        showModalDeletePopup,
        visibleDeletePopup,
        handleCancelDeletePopup,
        loading
    })
}