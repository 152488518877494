import {combineReducers} from 'redux'
import {ThemeReducer} from "../MenuClassement/theme/store/themeReducer";
import {CanalReducer} from "../MenuClassement/canal/store/canalReducer";
import userReducer from "../userData/userReducer"

export const rootReducer = combineReducers({
    ThemeReducer,
    CanalReducer,
    userReducer
})

