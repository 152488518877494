import { useEffect } from "react";
import CanalTable from "./components/List/canalTable";
import './canal.scss'
import {useLocation} from 'react-router-dom'
import i18next from 'i18next';
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import  Hooks  from "../canal/utils/hooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUserData } from "../../userData/userActions";
import LoadingScreen from "../../components/LoadingScreen";
import PagenotFound from "../pagenotfound/404notFound";


const Canal = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation();
    const {handleAddCanal } = Hooks()
    const {search} = useLocation()
    const obj = new URLSearchParams(search);
    useEffect(()=>{
        const lang = obj.get("lang")
        if(lang==="en" || lang==="fr"){
            i18next.changeLanguage(lang)
        }
    },[obj.get("lang")])
    // loading + user token verification
    const isAuth = useAppSelector((state:any)=>state.userReducer.isAuth)
    const loading = useAppSelector((state:any)=>state.userReducer.loading)
    const modules = useAppSelector((state:any)=>state.userReducer.modules)
    const isSuperAdmin = useAppSelector((state: any) => state.userReducer.isSuperAdmin)
    useEffect(()=>{
        dispatch(getUserData())
        },[])
    if(loading) return <LoadingScreen />
    else if(!isAuth) return <PagenotFound/>
    return (
        <div className={"divGlobalTable"}>
            <Header onMainPage={true} 
            title={t("classement.canals")} mainPageBtnAction={()=>handleAddCanal()} 
            firstBtnTitle={(modules.parameters_canal_post_add || isSuperAdmin) && t("Profile.add")} />
                <CanalTable />
        </div>
    )
}
export default Canal