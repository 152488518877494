import React, { FC, useState } from "react";
import Typography from '@mui/material/Typography';
import { CopyToClipboard } from "react-copy-to-clipboard";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmbedCodeTemplate from "./EmbedCodeTemplate";
import "../../theme.scss"
import { useSelector, useDispatch } from "react-redux";
import { setModalDisplayOff, setTitle } from "../../store/themeActions";
import { useTranslation } from "react-i18next";
import { ModalInputs } from '@empreinte/components.modal-inputs'
import {Button} from "@empreinte/components.button"
import ClearIcon from '@mui/icons-material/Clear';
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  headerStyle: {
    backgroundColor: 'rgb(3,155,229)',
    color: "white",
    padding: "1px"
  }

};


interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  isOpen: boolean,
  codeEmbed: string,
  url_export: string,
  integrationLink: string,
  title: string
}

const LinksIcon: React.FC<Props> = ({ isOpen, onClick, codeEmbed, url_export, integrationLink, title }: any) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cte = useSelector((state: any) => state.ThemeReducer.showModal)
  const [copiedExportChannel, setcopiedExportChannel] = useState(false);
  const [copiedExportPodcast, setcopiedExportPodcast] = useState(false);
  const [open, setOpen] = useState(isOpen);
  const handleClose = () => { setOpen(false); }

  return (<div>
    <ModalInputs
      title={`${t("classement.modaltitle")} ${title}`}
      maxWidth="md"
      open={isOpen}
      onCancel={() => { onClick(false); dispatch(setTitle("")) }}
      className="ModalStyle"
      CancelBtnName={`${(t("Profile.close"))}`}
      onClose={() => { onClick(false); dispatch(setTitle("")) }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      children={
          <div className="modal_container" >
            <div className="modal_export_row" >
              <b className="title_export" >{t("classement.Permalink")}</b>
              <div className="cadre_export" > {url_export}</div>
              <CopyToClipboard
                text={url_export}
                onCopy={() => {
                  setcopiedExportChannel(true)
                  setTimeout(() => { setcopiedExportChannel(false) }, 1000)
                }}
              >
                {
                  copiedExportChannel ?
                  <div className="rested_icon" >

                    <CheckCircleOutlineIcon sx={{ color: "green" }} /> 
                    </div>
                    :
                    <div className="triggered_icon" >
                    
                    <ContentCopyIcon />
                    </div>
                }
              </CopyToClipboard>


            </div>
            <div className="modal_export_row" >
              <b className="title_export" > {t("classement.Integrationlink")}</b>
              <div className="cadre_export" > {integrationLink}</div>


              <CopyToClipboard
                text={integrationLink}
                onCopy={() => {
                  setcopiedExportPodcast(true);
                  setTimeout(() => { setcopiedExportPodcast(false) }, 1000)
                }}
              >
                {
                  copiedExportPodcast ?
                  <div className="rested_icon" >
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                    </div> :
                    <div className="triggered_icon" >
                    <ContentCopyIcon />
                    </div>
                }
              </CopyToClipboard>



            </div>
            <div className="modal_export_row" >
              <b className="title_export" >{t("classement.Embedcode")}</b>
              <EmbedCodeTemplate link={codeEmbed} />
            </div>
            <div className="seperator" ></div>
            <div className="button_ct" >
           
            </div>
          </div>

      }
    />

  </div>)
}


export default LinksIcon;