
import { useEffect, useState } from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import Chip from '@mui/material/Chip';
import { useAppSelector } from "../../../../redux/hooks";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ModalInputs } from '@empreinte/components.modal-inputs'
import { ModalConfirm } from '@empreinte/components.modal-confirm'
import "../../canal.scss"

function useActionMenu({ record }: any) {
  const [lang, setLang] = useState("");
  const [readingPr, setReadingPr] = useState("");
  const [mbr, setmbr] = useState("");
  const [cdn, setSDN] = useState("");
  const [pushNotif, setPushNotif] = useState("");
  const [vbr, setvbr] = useState("");

  
  useEffect(() => {
    if (record.is_vbr === true) {
      setvbr(`${t("classement.Yes")}`)
    }
    if (record.is_vbr === false) {
      setvbr(`${t("classement.No")}`)
    }
    if (record.SendPushNotification === true) {
      setPushNotif(`${t("classement.Yes")}`)
    }
    if (record.SendPushNotification === false) {
      setPushNotif(`${t("classement.No")}`)
    }
    if (record.Language === "fr") {
      setLang("Français");
    }
    if (record.Language === "en") {
      setLang("Anglais")
    }
    if (record.Language === "ar") {
      setLang("Arab")
    }
    if (record.Language === "") {
      setLang("")
    }
    if (record?.ChannelConfigId?.ReadingProtocol === "-1") {
      setReadingPr(`${t("classement.Usedefaultconfiguration")}`)
    }
    if (record?.ChannelConfigId?.ReadingProtocol === "RTMP") {
      setReadingPr("RTMP")
    }
    if (record?.ChannelConfigId?.ReadingProtocol === "HDS") {
      setReadingPr("HDS")
    }
    if (record?.ChannelConfigId?.IsCdn === true) {
      setSDN(`${t("classement.Yes")}`)
    }
    if (record?.ChannelConfigId?.IsCdn === false) {
      setSDN(`${t("classement.No")}`)
    }
    if (record?.ChannelConfigId?.ShowMbr === -1) {
      setmbr(`${t("classement.Usedefaultconfiguration")}`)
    }
    if (record?.ChannelConfigId?.ShowMbr === 0) {
      setmbr(`${t("classement.Disable")}`)
    }
    if (record?.ChannelConfigId?.ShowMbr === 1) {
      setmbr(`${t("classement.Enable")}`)
    }

  }, [record.SendPushNotification, record.is_vbr, record?.ChannelConfigId?.ShowMbr, record?.ChannelConfigId?.IsCdn, record?.ChannelConfigId?.ReadingProtocol, record.Language, record?.ChannelConfigId?.ReadingProtocol])


  const openSnackbar = useAppSelector((state: any) => state.CanalReducer.openSnackbar);
  const modules = useAppSelector((state: any) => state.userReducer.modules)
  const isSuperAdmin = useAppSelector((state: any) => state.userReducer.isSuperAdmin)

  const [clicked, setClicked] = useState<boolean>(false);

  const { t } = useTranslation();

  const { handleShowModal, visible,
    handleCancel, handleShowModalExport,
    visibleExport, handleCancelModalExport,
    CopyUrlAuditeur, handleEditCanal, DeleteChannel, visibleDeletePopup, handleShowModalDeletePopup, handleCancelDeletePopup, loading } = Hooks()

  useEffect(() => {
    setClicked(false)
  }, [openSnackbar])
  let servers = record?.servers?.map((s: any) => {
    return (
      <Chip label={s} />
    )
  })
  return (
    <div>
      <div className="actionCanal"  >

        {(modules?.parameters_canal_post_edit || isSuperAdmin) &&
          <div has-permission="parameters_canal_post_edit"  >
            <Tooltip placement="top" title={`${t("Profile.edit")}`}>
              <ModeEditIcon color="action" className={"icon_action"} fontSize="small"
                onClick={() => handleEditCanal(record)} />
            </Tooltip>
          </div>}
        {(modules?.parameters_canal_get_detail || isSuperAdmin) &&
          <div has-permission="parameters_canal_get_detail" >
            <Tooltip placement="top" title={`${t("Profile.details")}`}>
              <RemoveRedEyeIcon color="action" className={"icon_action"} fontSize="small"
                onClick={handleShowModal} />
            </Tooltip>
          </div>}
        {(modules?.parameters_canal_delete_channel || isSuperAdmin) &&
          <div has-permission="parameters_canal_delete_channel" >
            <Tooltip placement="top" title={`${t("Profile.delete")}`}><DeleteIcon fontSize="small" color="action" className={"icon_action"} onClick={() => { handleShowModalDeletePopup() }}  /></Tooltip>
          </div>}
        {/*Modal Details*/}
        <ModalInputs
          maxWidth="sm"
          onClose={handleCancel}
          dividers={true}
          aria-labelledby="customized-dialog-title"
          open={visible}
          title={`${t("classement.Channeldetails")} " ${record.Title} "`}
          CancelBtnName={`${t("Profile.close")}`}
          onCancel={handleCancel}
          children={
              <div className={"channels-details-modal"}>
                <div className={"channels-details-modal-container"}>
                  <span className={"channels-details-modal-title"}>{t("classement.identifier")}</span>
                  <span className={"channels-details-modal-value"}>{record.Id}</span>
                </div>
                <div className={"channels-details-modal-container"}>
                  <span className={"channels-details-modal-title"}>{t("classement.Title")}</span>
                  <span className={"channels-details-modal-value"}>{record.Title}</span>
                </div>
                <div className={"channels-details-modal-container"}>
                  <span className={"channels-details-modal-title"}>{t("classement.Description")}</span>
                  {
                    record.Description ?
                      <span className={"channels-details-modal-value"}>{record.Description}</span>
                      :
                      <span className={"channels-details-modal-value"}>{t("classement.Aucun")}</span>

                  }
                </div>

                <div className={"channels-details-modal-container"}>
                  <strong className={"channels-details-modal-title"}>{t("classement.Language")}</strong>
                  {
                    record.Language ?
                      <span className={"channels-details-modal-value"}>{record.Language}</span>
                      :
                      <span className={"channels-details-modal-value"}>{t("classement.Aucun")}</span>

                  }
                </div>
                <div className={"channels-details-modal-container"}>
                  <span className={"channels-details-modal-title"}>{t("classement.Quotas")} (Mo)</span>
                  <span className={"channels-details-modal-value"}>{record.Quota}</span>
                </div>
              </div>
          }
        />


        <ModalConfirm
          open={visibleDeletePopup}
          onClose={handleCancelDeletePopup}
          title={`${t("classement.Are you sure you want to delete the channel?")}`}
          content={`${record.Title} ${t("classement.The selected item will be deleted")}`}
          CancelBtnName={`${t("classement.No")}`}
          ConfirmBtnName={`${t("classement.Yes")}`}
          onConfirm={() => { setClicked(true); DeleteChannel(record.Id) }}
          onCancel={handleCancelDeletePopup}
        />


        {/*Modal Details*/}


        {/* Export Modal
        <ModalInputs
          title={`${t("classement.modaltitle")} ${record.Title}`}
          maxWidth="sm"
          dividers={true}
          onClose={handleCancelModalExport}
          aria-labelledby="customized-dialog-title"
          open={visibleExport}
          CancelBtnName={`${t("Profile.close")}`}
          onCancel={handleCancelModalExport}
          children={
            <div className="div_Url_diffusion">
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <span>{t("classement.Integrationlink")}</span>
                </Grid>

                <Grid item xs={7}>
                  <TextareaAutosize id="myUrlAuditeurCanal" name={"auditorUrl"} minRows={3}
                    style={{ width: '100%' }}
                    value={`<iframe src="" width="820" height="565" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen></iframe>${urlExportChannel}`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Tooltip title={"Copier"}>
                    {
                      isCopied ?
                        <CheckCircleOutlineIcon sx={{ color: "green" }} />
                        :
                        <ContentCopyIcon color="action" onClick={() => { CopyUrlAuditeur(); setCopy() }} style={{ cursor: 'pointer' }} />
                    }
                  </Tooltip>
                </Grid>

              </Grid>


            </div>
          }
        /> */}
      </div>
    </div>
  );
}

export default useActionMenu;
