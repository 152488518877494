import DataGridDemo from "./DataTable";
import { useAppSelector } from "../../../../redux/hooks";
import { useSelector } from "react-redux";
import { DataCanal } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../canal.scss";
import { setOpenSnackBar } from "../../store/canalActions";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { SnackBar } from "../../../../utils/SnackBar";
import { useDispatch } from "react-redux";
import { SearchBar } from "@empreinte/components.search-bar";
import { Loader } from "@empreinte/components.loader"
import { ModalConfirm } from '@empreinte/components.modal-confirm'

const CanalTable = () => {
  const [open, setOpen] = useState(false);
  const openSnackbar = useAppSelector(
    (state: any) => state.CanalReducer.openSnackbar
  );
  const handleClickOpen = () => {
    setOpen(true);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOpenSnackBar({ open: false, message: "" }));
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  // ! pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  // use Selector redux
  const modules = useAppSelector((state: any) => state.userReducer.modules)
  const isSuperAdmin = useAppSelector((state: any) => state.userReducer.isSuperAdmin)
  const CheckBox_cte = useAppSelector(
    (state: any) => state.CanalReducer.checkBoxDelete
  );
  const { data } = DataCanal();
  const Id = useSelector((state: any) => state.CanalReducer.checkBoxDelete);
  const { getListChannels, channelsMultiDelete } = Hooks();
  const [searchText, setSearchText] = useState("");
  const multiDeleteData = useSelector(
    (state: any) => state.CanalReducer.multiDeleteData
  );
  const singleDeleteData = useSelector(
    (state: any) => state.CanalReducer.deleteData
  );
  const deleteLoading = useSelector(
    (state: any) => state.CanalReducer.deleteLoading
  );
  useEffect(() => {
    const offset = (currentPage - 1) * pageSize;
    getListChannels({
      variables: {
        where: { titleContains: searchText },
        pagination: { limit: pageSize, offset },
      },
    });
  }, [searchText, multiDeleteData, singleDeleteData, currentPage, pageSize]);

  const handleKeyDown = (event : any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  
  return (
    <div className="bg_datatable">
      {deleteLoading && <Loader isOpen={true} />}
      <div className="datable_marginall">
        <div className={CheckBox_cte.length && "onDelete"}>
          {(modules?.parameters_canal_list_dtable || isSuperAdmin) &&
            <span has-permission="parameters_canal_list_dtable" >{CheckBox_cte.length ? (
              <DeleteIcon sx={{ marginLeft: "13px" }} onClick={handleClickOpen} />
            ) : null}
            </span>}
          <ModalConfirm
            title={`${t("classement.Are you sure you want to delete the channelM?")}`}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            content={`${t("classement.SelectedItemsForDelete")}`}
            CancelBtnName={`${t("classement.No")}`}
            ConfirmBtnName={`${t("classement.Yes")}`}
            onCancel={handleClose}
            onConfirm={() => {
              channelsMultiDelete(Id);
              handleClose();
            }}

          />

          
            <div className={`rect_filterCanal ${CheckBox_cte.length ? "getToTheLetfLittle" : ""} `}>
              <SearchBar
                className="inputFilter"
                placeholder={`${t("Profile.search")}`}
                setSearchText={(e) => setSearchText(e.target.value)}
                onKeyDown={handleKeyDown}
                value={searchText}
                name={"search"}
              ></SearchBar>
            </div>
        </div>
        {(modules?.parameters_canal_list_dtable || isSuperAdmin) &&
          <div className={"dataTable"} has-permission="parameters_canal_list_dtable">
            {openSnackbar.open && <SnackBar />}
            <DataGridDemo
              searchText={searchText}
              dataSource={data}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />
          </div>
        }
      </div>
    </div>
  );
};
export default CanalTable;
