import {CanalList} from "./canalConstants";
const {AddCanalConstraintsData  } = CanalList()

const CanalListINITIALSTATE = {
    addCanalConstraintsData:AddCanalConstraintsData(),
    editData:{},
    channelList:[],
    Servers:[],
    ChannelsConf:[],
    urlExportChannel:"",
    editchannelConfData:{
        Id:"",
        IsCdn:false,
        ReadingProtocol:"",
        DefaultBitrateMobile:"",
        DefaultBitrateTablet:"",
        DefaultBitrateComputer:"",
        ShowMbr:-1
    },
    editDataForm:{
        Title:"",
        Description:"",
        Language:"",
        ServerIDs:[],
        ServersIds:[],
        PermalienVideo:"",
        EmbedVideo:"",
        SendPushNotification:true,
        is_vbr:false,
        Upload_Url:"",
        Upload_Path:"",
        Uid:"",
        ShowMbr:"",
        Quota:0
    },
    loading:true,
    deleteLoading:false,
    checkBoxDelete:[],
    multiDeleteData:[],
    openSnackbar:false,
    deleteData:[],
    isDoneAddEdit:false,
    isAbleToSubmit:false,
    
}
export const CanalReducer = (state=CanalListINITIALSTATE , action:any)=>{

    switch (action.type){
            case "set_single_delete":
                return {
                    ...state,
                    deleteData:action.payload,
                }
            case "SET_Submit":
                return {
                    ...state,
                    isAbleToSubmit:action.payload,
                }
            case "SET_isDoneAddEdit":
                return {
                    ...state,
                    isDoneAddEdit:action.payload,
                }
            case "SET_openSnackBar" : 
            return {
                ...state,
                openSnackbar:action.payload   
            }
        case "MultiDelete_data":
            return {
                ...state,
                multiDeleteData:action.payload,
            }
        case "Set_CheckBox_delete":
            return  {
                ...state,
                checkBoxDelete:action.payload
            }
        case "SET_EditCanalData" :
                return {
                    ...state,
                editData: action.payload
                }
        case "Set_showMbr":
            return {
                ...state,
                ShowMbr:action.payload
            }
        case "SET_EditCanalFormConfData" :
            return {
                ...state,
            editDataForm: action.payload
            }
        case "SET_EditCanalConfData" :
            return {
                ...state,
            editchannelConfData: action.payload
            }
        case "SET_UrlExportChannelConf" :
            return {
                ...state,
                urlExportChannel: action.payload
            }
        case "SET_ChannelList" :
            return {
                ...state,
                channelList: action.payload
            }
        case "SET_Servers" :
            return {
                ...state,
                Servers: action.payload
            }
        case "SET_ChannelConfig" :
            return {
                ...state,
                ChannelsConf: action.payload
            }
        case "SET_AddCanalActions" :
            const {addCanalConstraintsDataNameChange,addCanalConstraintsDataValueChange}=action.payload
            if(addCanalConstraintsDataNameChange==="errorMessage"){
                return {
                    ...state,
                    addCanalConstraintsData:{...state.addCanalConstraintsData, [addCanalConstraintsDataNameChange]:state.addCanalConstraintsData.errorMessage.concat(addCanalConstraintsDataValueChange) }
                }
            }
            return {
                ...state,
                addCanalConstraintsData:{...state.addCanalConstraintsData, [addCanalConstraintsDataNameChange]:addCanalConstraintsDataValueChange},
                editDataForm:{...state.editDataForm, [addCanalConstraintsDataNameChange]:addCanalConstraintsDataValueChange},
                editData:{...state.editData, [addCanalConstraintsDataNameChange]:addCanalConstraintsDataValueChange}
            }
        case "SET_EditChannelValue":
            return {
                ...state,
                editDataForm:{...state.editDataForm,...action.payload,
                IsCdn:action.payload.ChannelConfigId.IsCdn,
                ChannelConfigIdID:action.payload.ChannelConfigId.Id,
                ServersIds:action.payload.Servers?.map((el:any)=>el.Id)
                },
                editchannelConfData:{...state.editchannelConfData,
                                    ...action.payload.ChannelConfigId}
                
            }
            case "SET_Loading" :
                return {...state,
                    loading:action.payload
                }
            case "SET_DeleteLoading" :
                return {...state,
                    deleteLoading:action.payload
                }
        default: {
            return state
        }

    }

}

