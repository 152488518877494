import  { AlertColor } from '@mui/material/Alert';

export const CanalList = () =>{
    const AddCanalConstraintsData = () => {
        const errorMessage :{
            error:string;
            openSnack:boolean;
            errType:AlertColor;
            position:{ horizontal: any,vertical:any}
        }[]=[]
        return (
            {
                errorMessage,
                reaction:false,
                title:"",
                descCanal:"",
                language:"fr",
                ServerIDs:[],
                quota:0,
                EmbedVideo:"",
                Upload_Path:"" ,
                PermalienVideo:"",
                ChannelConfigIdID:null,
                description:"" ,
                Upload_Url:"",
                is_vbr:false,
                sendPushNotification:true,
                defaultBitrateComputer:"",
                defaultBitrateTablet:"",
                ReadingProtocol:"",
                IsCdn:false,
                ShowMbr:"",
                defaultBitrateMobile:""
            }
        )
    }

    return({
        AddCanalConstraintsData
    })
}