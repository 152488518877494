import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from "react";

const EmbedCodeTemplate = ({link}) => {
    const template = `${link}`
    const [copyIframe, setCopyIframe] = useState(false)
    const copyLink = (value) => {
        navigator.clipboard.writeText(value);
        setCopyIframe(true)
        setTimeout(() => { setCopyIframe(false) }, 1000)

    };
    return (
    <>
        <textArea className="textAreaTemplate" >{template}</textArea>
        {copyIframe ?
        <div className="rested_icon" >
        <CheckCircleOutlineIcon sx={{color:"green"}} />
        </div>
        :
        <div className="triggered_icon" >
        <ContentCopyIcon onClick={()=>copyLink(template)} />
        </div>
        }
    </>)
}

export default EmbedCodeTemplate;
