import gql from "graphql-tag";

export const graphQL_shema = ()=> {


 

    const Get_All_Themes = gql`
    mutation($where:ThemeWhereInput $pagination: Pagination $orderBy:ThemeOrder) {
        Themes(where:$where pagination:$pagination orderBy:$orderBy){
        totalCount
       filtredCount
       edges{node{Id Title Logo Visible Occurrences classifiedBy Orderr Parent{Id Title} UpdatedAt  description WebtvChannel{Id Title}
       Directory Directory SubDirectory Alias UrlName }}}
      }
    `;
    const GetThemeById = gql`
    mutation($id:Int!) {
        theme(id:$id){
         Id Title Logo description Directory classifiedBy Visible
         WebtvChannel{
            Id Title
         }
         Parent{
            Id
            Title
         }
        }
      }
    `

      const createTheme = gql`
      mutation($input: CreateThemeInput!){CreateTheme(input:$input){Id}}`

   const getChannelsConfig = gql`
   mutation{channelConfigs{totalCount edges{node{Id IsCdn ReadingProtocol DefaultBitrateComputer }}}}` 


    const deleteTheme = gql`
    mutation($id: [Int]!) {
        deleteTheme(id:$id)
  }
    
    `

 
    const uploadFile = gql`
        mutation($file: Upload!, $flag:String) {
            UploadToS3(file: $file , flag:$flag) {
                code
                UrlFile
            }
        }
    `;

    const updateTheme = gql`
        mutation($input: UpdateThemeInput!){
            updateTheme(input:$input){Id}
        }  
        `



const getThemesLink = gql`
mutation($id: Int!) {
    ThemeLinks(id:$id){
     UrlPermalien
      codeEmbed
      UrlEmbed
     
     
  }
  }

`

    return ({
        getThemesLink,
        Get_All_Themes,
        GetThemeById,
        deleteTheme,
        createTheme,
        uploadFile,
        updateTheme,
         getChannelsConfig,
    })

}