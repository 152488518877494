import * as React from 'react';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../redux/hooks";

export  const SnackBar =() =>{
  const openSnackbar = useAppSelector(state=>state.CanalReducer.openSnackbar);

  const [open, setOpen] = React.useState(true);

 
  React.useEffect(()=>{setOpen(openSnackbar.open)},[openSnackbar])

  const handleClose = () => {
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        sx={{ color: "#ffff" }}
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (


<Snackbar
  open={open}
  anchorOrigin={{ vertical:'top',horizontal: 'center' }}
  ContentProps={{
    sx: {
      backgroundColor: "#d32f2f"
    }
  }}
  onClose={handleClose}
  autoHideDuration={2000}
  message={openSnackbar.message}
  action={action}
/>


)}