import { TYPES } from "./types";

const initState = {
    loading:true,
    isAuth:false,
    userData : {},
    roles:[],
    modules:{},
    isSuperAdmin: false
}
type actionProps = {
    type:string,
    payload:any
}
const userReducer = (state=initState,action:actionProps)=>{
    switch (action.type) {
        case TYPES.GET_USER_DATA:
            return {
                ...state,
                userData:action.payload.userData.user,
                roles:action.payload.userData.roles,
                modules:action.payload.userData.modules,
                isAuth:true,
                loading:false
            }
        case TYPES.UNAUTHORIZED : 
            return {
                ...state,
                isAuth:false,
                loading:false,
                isSuperAdmin: false
            }
        case TYPES.IS_SUPER_ADMIN :
            return {
                ...state,
                isSuperAdmin: true
            }
        default:
            return state
    }
}
export default userReducer