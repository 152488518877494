
export const ThemeList = () =>{
    const AddThemeConstraintsData = () => {
    return (
            {
                Title:"",
                WebtvChannelIDs:[],
                classifiedBy:"title",
                description:"",
                Visible:true,
                Order:0,
                Parent_id:0,
                Logo:"",
                asc:"",
                ascChecker:""
            }
        )
    }
    return({
        AddThemeConstraintsData
    })
}