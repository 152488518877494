import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { GraphQLFetchData } from '../../utils/graphQLFetchData'
import { useAppSelector } from "../../../../redux/hooks";
import { UploadImage } from '@empreinte/components.upload-image'
import { message } from "antd";

const VignetteUpload = () => {
  const { t } = useTranslation();
  const { uploadFile } = GraphQLFetchData()
  const imageUrl = useAppSelector((state: any) => state.ThemeReducer.addThemeConstraintsData.Logo)
  
  const ALLOWED_EXTENSIONS = ["jpeg", "png", "apng", "avif","jpg","svg","webp"];
  const MAX_THUMBNAIL_SIZE_MB = 5;
  const ACCEPTED_DIMENSIONS = [
    { width: 720, height: 480 },
    { width: 1280, height: 720 },
    { width: 1920, height: 1080 },
];

  const beforeUpload = async (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const fileSizeMB = file.size / (1024 * 1024);

    const isValidExtension = fileExtension && ALLOWED_EXTENSIONS.includes(fileExtension);
    const isValidSize = fileSizeMB <= MAX_THUMBNAIL_SIZE_MB;

    if (!isValidExtension) {
      message.error(t("classement.error_format"));
      return false;
    }

    if (!isValidSize) {
      message.error(t("classement.error_size"));
      return false;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
  
    return new Promise((resolve, reject) => {
      img.onload = function () {
        const width = img.width;
        const height = img.height;
  
        const acceptedDimensions = ACCEPTED_DIMENSIONS.some(
          (dimension) => width === dimension.width && height === dimension.height
        );
        if (!acceptedDimensions) {
        message.error(t("classement.error_dimensions"));
          resolve(false);
        } else {
          resolve(true);
        }
      };
      img.onerror = reject;
    });
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log(e.target.files)
    if (file && (await beforeUpload(file))) {
      uploadFile({ variables: { file, flag: "theme" } });
    }
  };
  return (
    <UploadImage
      title={t("classement.upload")}
      component="span"
      fileURL={imageUrl}
      handleChange={onChange}
      startIcon={<FileDownloadOutlinedIcon className="IconUpload" />}
      variant="contained"
      style={{ whiteSpace: 'nowrap' ,marginLeft:"0 !imporant"}}
    />
  );
}
export default VignetteUpload;
