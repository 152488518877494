import { ThemeList } from "./themeConstants";

const { AddThemeConstraintsData } = ThemeList()

const ThemeListINITIALSTATE = {
    addThemeConstraintsData: AddThemeConstraintsData(),
    themes: [],
    linksThemes: [],
    addthemeresult: "",
    channelList: [],
    channels: [],
    WebtvChannelIDs:[],
    themestodelete: [],
    loading: true,
    links :[],
    showModal : false,
    openSnackbar:false,
    addEditLoader:false,
    title:"",
}
export const ThemeReducer = (state = ThemeListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "ADDTHEME":
            return {
                ...state,
                addthemeresult: action.payload
            }
        case "SET_TITLE":
            return {
                ...state,
                title:action.payload,
            }

        case "SET_Add_edit" :
            return {
                ...state,
                addEditLoader:action.payload,
            }
        case "SET_openSnackBar" : 
        return {
            ...state,
            openSnackbar:action.payload

        }
        case "SET_Themes_TO_DELETE":
            return {
                ...state,
                themestodelete: action.payload
            }
        case "SET_MODAL_DISPLAY":
            return {
                ...state,
                showModal : action.payload
            }
        case "GET_LINKS" :
            return {
                ...state,
                links:action.payload,
            }
        case "SET_CHANNELS":
            return {
                ...state,
                channels: action.payload
            }
        case "SET_Links_Themes":
            return {
                ...state,
                linksThemes: action.payload
            }
        case "SET_List_Themes": {
            return {
                ...state,
                themes: action.payload
            }
        }
        case "SET_AddTheme":
            const { addThemeConstraintsDataNameChange, addThemeConstraintsDataValueChange } = action.payload
            return {
                ...state,
                addThemeConstraintsData: { ...state.addThemeConstraintsData, [addThemeConstraintsDataNameChange]: addThemeConstraintsDataValueChange }
            }
        case "SET_Loading":
            return {
                ...state,
                loading: action.payload
            }
        case "SET_ID":
            return {
                ...state,
                addThemeConstraintsData: { ...state.addThemeConstraintsData,id:action.payload}
            }
        case "SET_EditThemeData":
            const id = action.payload.Id
            delete action.payload.Id
            return {
                ...state,
                WebtvChannel:action.payload.WebtvChannel,
                addThemeConstraintsData:{...action.payload,id,
                    Parent_id:action.payload?.Parent?.Id,
                    WebtvChannelIDs:action.payload.WebtvChannel?.map((el:any)=>el.Id)
                }
            }
        case "SET_IMG_URL":
            return {
                ...state,
                addThemeConstraintsData:{
                    ...state.addThemeConstraintsData,
                    Logo : action.payload
                }
            }
        default: {
            return state
        }

    }

}

