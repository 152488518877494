import React, {Suspense} from 'react';
import App from './App';
import ApolloClient from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import {ApolloProvider} from '@apollo/react-hooks';
import {setContext} from 'apollo-link-context';
import {Provider} from "react-redux";
import store from "./redux/store";
import './i18n';
import {Spin} from "antd";
import {BrowserRouter as Router} from 'react-router-dom';

const link = createUploadLink({ uri: window.process.env.API_HOST_GLOBAL+"/query" });
const authLink = setContext(() => {
    /*const token = localStorage.getItem('jwtToken');*/
    const token = localStorage.getItem("token")
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache()
});


export default (
    <Suspense fallback={(<Spin className="Spin">
        <div></div>
    </Spin>)}>
        <Provider store={store}>
            <ApolloProvider client={client}>
            <Router>
                <App/>
            </Router>
            </ApolloProvider>
        </Provider>
    </Suspense>
);