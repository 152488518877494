import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { DataTheme } from "../../utils/Data";
import useDataTableMedia from "./DataTable";
import { Hooks } from "../../utils/hooks";
import { SnackBar } from "../../../../utils/SnackBar";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import "../../theme.scss";
import { SearchBar } from "@empreinte/components.search-bar";
import { Loader } from "@empreinte/components.loader"
import { ModalConfirm } from '@empreinte/components.modal-confirm'
import {useDispatch} from "react-redux";
import { setAddThemeActions} from "../../store/themeActions";


const ThemeTable = () => {
  const [open, setOpen] = useState(false);
  const { columns, data } = DataTheme();
  const { t } = useTranslation();
  const { getThemesList, onDeleteTheme } = Hooks();
  const modules = useAppSelector((state: any) => state.userReducer.modules)
  const isSuperAdmin = useAppSelector((state: any) => state.userReducer.isSuperAdmin)
  const dispatch = useDispatch()
  const openSnackbar = useAppSelector(
    (state: any) => state.ThemeReducer.openSnackbar
  );
  const themestodelete = useAppSelector(
    (state: any) => state.ThemeReducer.themestodelete
  );
  const deleteLoading = useAppSelector(
    (state: any) => state.CanalReducer.deleteLoading
  );
  const order =useAppSelector(
    (state: any) => state.ThemeReducer.addThemeConstraintsData.Order
  );
  const asc = useAppSelector(
    (state: any) => state.ThemeReducer.addThemeConstraintsData.asc
  );
  const ascChecker = useAppSelector(
    (state: any) => state.ThemeReducer.addThemeConstraintsData.ascChecker
  );
  // fonction pour afficher le tableau de l'interface
  // ! pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { DataTable, selectionModel  } = useDataTableMedia({
    columns: columns,
    dataSource: data,
    updateEntityPath: "update-product",
  });
  const selectedRowCount = selectionModel.length;
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const offset = (currentPage - 1) * pageSize;
    if(asc?.length){
      getThemesList({
        variables: {
          where: { titleContains: searchText },
          pagination: { limit: pageSize, offset },
          orderBy: {field:"TITLE",direction:asc === "asc" ? "ASC" : "DESC"}
        },
      });
      dispatch(setAddThemeActions({
        addThemeConstraintsDataNameChange: "asc",
        addThemeConstraintsDataValueChange: ""
    }));
    
    } else {
      getThemesList({
        variables: {
          where: { titleContains: searchText },
          pagination: { limit: pageSize, offset },
          orderBy: {field:"TITLE",direction:"ASC" }
        },
      });
      dispatch(setAddThemeActions({
        addThemeConstraintsDataNameChange: "ascChecker",
        addThemeConstraintsDataValueChange: ""
    }));

    }
    
  }, [searchText, pageSize, currentPage,order,ascChecker]);
  
  const handleDeleteTheme = async () => {
    await onDeleteTheme(themestodelete);
    setSearchText("");
    handleClose();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (event : any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  return (
    <div>
      {deleteLoading && <Loader isOpen={true} />}
      <div className="Filter">
        <div className="div_rect rect">
          <ModalConfirm
            open={open}
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            content={`${t("classement.SelectedItemsForDelete")}`}
            aria-describedby="alert-dialog-description"
            title={`${t("Profile.DeleteMsgConfirmTheme")}`}
            CancelBtnName={`${t("classement.No")}`}
            onCancel={handleClose}
            ConfirmBtnName={`${t("classement.Yes")}`}
            onConfirm={handleDeleteTheme}
          />
          {(modules?.parameters_theme_delete_multi || isSuperAdmin) && selectedRowCount > 0 &&
          <div className="centerFilter">
            <IconButton
              has-permission="parameters_theme_delete_multi"
              onClick={() => {
                handleClickOpen();
              }}
              style={{ display: !themestodelete?.length ? "none" : "grid" ,padding:"3px 18px", border:"1px solid rgba(0, 0, 0, 0.15)",borderRadius:"4px"}}
              type="submit"
              aria-label="search"
            >
              <DeleteIcon sx={{ color: "black", marginLeft: "6px" }} />
            </IconButton>
            <div style={{ display:"flex" ,alignItems:"center",width:"20rem",fontSize:"14px",fontWeight: "400",marginLeft:"5px", color: "rgba(0, 0, 0, 0.85)"}}>
            {selectedRowCount}
            <span style={{marginLeft:"5px" }}>{t("classement.selectedItems")}</span>
          </div>
          </div>
            }
            
          <div
            className={
              !themestodelete?.length ? "rect_filter" : "rect_filter_delete"
            }
          >
            <SearchBar
              placeholder={`${t("Profile.search")}`}
              setSearchText={(e) =>{
              setSearchText(e.target.value)
              setCurrentPage(1)
            }}
              onKeyDown={handleKeyDown}
              value={searchText}
            ></SearchBar>
          </div>
        </div>
      </div>

      {(modules?.parameters_theme_list_dtable || isSuperAdmin) &&
        <div className={"dataTable"} has-permission="parameters_theme_list_dtable">
          {openSnackbar.open && <SnackBar />}
          <DataTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        </div>
      }
    </div>
  );
};
export default ThemeTable;
