export function setChannelList(e:any){
    return{
        type:"SET_ChannelList",
        payload:e,
    }
}
export function setOpenSnackBar(e:any){
    return{
        type:"SET_openSnackBar",
        payload:e,
    }
}

export function setOnAddEditLoader (status:any) {
    return {
        type:"SET_isDoneAddEdit",
        payload:status
    }
}

export function setSingleDelete(e:any) {
    return {
        type:"set_single_delete",
        payload:e
    }
}
export function setSubmit (e:any) {
    return {
        type:"SET_Submit",
        payload:e,
    }
}
export function setEditChannelData(e:any){
    return{
        type:"SET_EditCanalData",
        payload:e,
    }
}
export function setEditChannelConfData(e:any){
    return{
        type:"SET_EditCanalConfData",
        payload:e,
    }
}
export function setEditChannelFormData(e:any){
    return{
        type:"SET_EditCanalFormConfData",
        payload:e,
    }
}

export function setCheckBoxDelete (e:any) {
  
    let idList = [];
    idList = e.map((i:any)=>i);
  

    return {
        type:"Set_CheckBox_delete",
        payload:idList
    }
}


export function setMultiDeleteData(e:any) {
    return {
        type:"MultiDelete_data",
        payload:e
    }
}



export function setUrlExportChannelConf(e:any){
    return{
        type:"SET_UrlExportChannelConf",
        payload:e,
    }
}
export function setServerList(e:any){
    return{
        type:"SET_Servers",
        payload:e,
    }
}
export function setChannelConfig(e:any){
    return{
        type:"SET_ChannelConfig",
        payload:e,
    }
}
export function setEditChannelValue(e:any){
    return {
        type:"SET_EditChannelValue",
        payload:e
    }
}
export function setAddCanalActions(e:any){
    return{
        type:"SET_AddCanalActions",
        payload:e,
    }
}
export function setLoading(e:boolean){
    return {
        type:"SET_Loading",
        payload:e
    }
}
export function setDeleteLoading(e:boolean){
    return {
        type:"SET_DeleteLoading",
        payload:e
    }
}
export function setEditThemeData(e:any){
    return{
        type:"SET_EditThemeData",
        payload:e,
    }
}
