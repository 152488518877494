import {useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { setCheckBoxDelete } from '../../store/canalActions';
import {useTranslation} from "react-i18next";
import UseActionMenu from "./actionsUserTable";
import { useMediaQuery } from '@material-ui/core';
import {Datagrid} from "@empreinte/components.datagrid"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { GridNorows } from "@empreinte/components.grid-norows";
import {useWindowSize} from '../../../../utils/resizeEvent'

export default function DataGridDemo({dataSource,setCurrentPage,currentPage,setPageSize,pageSize}:any) {
const {t} = useTranslation();
    const showPhoneColumnsUltra = useMediaQuery('(min-width:500px)');
    const showPhoneColumns = useMediaQuery('(min-width:757px)');
    const showmobile = useMediaQuery('(min-width:1000px)');
    const handleIconClick = (e:any,y:any) => {
        //sort functions for dispathc and redux struct
    };
    const windowSize = useWindowSize()
    const hideColumnTitle = () => {
        return  windowSize < 510
     };
    const updatedColumns = [
        {
            headerName: 'ID',
            field: 'id',
            key: 0,
            flex:1,
            align: 'center',
            minWidth:30,
            maxWidth:100,
            sortable:false,
            headerAlign:'center',
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">ID</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ),  
            
        },
        {
            headerName: t('classement.Title'),
            field: 'Title',
            key: 1,
            align: 'center',
            flex:1,
            minWidth:50,
            width:showmobile ? 300 : 100,
            sortable:false,
            hide:hideColumnTitle(),
            headerAlign:'center',
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">{t("classement.Title")}</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ),  

        },
        {
            headerName: t("classement.Language"),
            field: 'Language',
            key: 2,
            align: 'center',
            flex:1,
            minWidth:30,
            sortable:false,
            headerAlign:'center',
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">{t("classement.Language")}</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ),  
         
        },
        {
            headerName: 'Quotas (Mo)',
            field: 'Quota',
            key: 3,
            minWidth:80,
            align: 'center',
            flex:1,
            sortable:false,
            headerAlign:'center',
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">Quotas (Mo)</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ),  

        },
        {
            headerName: 'Actions',
            key: 'action',
            flex:1,
            sortable:false,
            align: 'center',
            minWidth:120,
            maxWidth:180,
            headerAlign:'center',
            renderCell: (action: any, record: any) => {
           return (
                    <UseActionMenu record={action.row} />
                )
            },
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">Actions</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ),  
        },
    ];
    const [source, setSource] = useState([])
    useEffect(() => {
        const list = dataSource?.content?.map((row: any) => {
            let arrayOfnodes: any = [];
            arrayOfnodes = ({ ...row.node,id:row.node.Id, servers: Array.from(row.node?.Servers, ({ Name }) => Name) })
            arrayOfnodes.Language = arrayOfnodes.Language === "ar" 
            ? t("classement.Arab")
            :arrayOfnodes.Language === "fr" 
            ? t("classement.French")
            : arrayOfnodes.Language === "en" 
            ? t("classement.English")
            : ""
            return arrayOfnodes
        })
        setSource(list)
    }, [dataSource.content]);

    const dispatch = useDispatch();
    const selectedRowsChange = (selectedRows:any)=>{
        dispatch(setCheckBoxDelete(selectedRows));
    }

  return (
    <>
        {source?.length ===0 && currentPage === 1 ?  <GridNorows content={t("classement.No matching records found")} /> :
        <Datagrid
            rows={source?.length ? source : []}
            columns={showPhoneColumns ? updatedColumns as any : updatedColumns.filter(el=> el.field!=="Quota" && el.field!=="Language" )}
            checkboxSelect={selectedRowsChange}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            totalEntities={dataSource?.totalElements}
            EntriesName={`${t("classement.entries")}`}
            OfPageName={`${t("classement.of")}`}
            ShowName={`${t("classement.Show")}`}
            height={"100%"}
            
        />
        }
    </>
  );
  
}

