import {useMutation} from "@apollo/react-hooks";
import {graphQL_shema} from "./graphQL";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
  import {setListThemes, setLoading,setListThemesLink,saveImageUrl,setOpenSnackBar, setAddEditLoader} from "../store/themeActions";
 import { message } from "antd";
import { setChannelConfig,setEditThemeData } from "../../canal/store/canalActions";

export const GraphQLFetchData=()=> {
    const dispatch = useDispatch()
    const nav = useNavigate()
    const [GetListThemes]
        = useMutation(graphQL_shema().Get_All_Themes, {
            fetchPolicy:  "no-cache",
             variables: { orderBy:{direction:"ASC",field:"TITLE"} },
        onCompleted :(data)=>{
          if(data?.Themes)
             dispatch(setListThemes(data?.Themes));
        }        
    })
// //getChannelsConfig
   const [getChannelConfig] = useMutation(graphQL_shema().getChannelsConfig,
   {
    fetchPolicy:  "no-cache",
      variables: { },
onCompleted :(data)=>{
    if(data?.SubscriptionGroups){
  if(data?.channelConfigs)
  dispatch(setChannelConfig(data?.channelConfigs.edges))  
      }else {  }}
 })
  const [GetThemeById] = useMutation(graphQL_shema().GetThemeById,{
    fetchPolicy:  "no-cache",
    variables: { },
    onCompleted :(data)=>{
      dispatch(setEditThemeData(data?.theme))  
    }
  })
  

const [createTheme] = useMutation(graphQL_shema().createTheme,
{
 fetchPolicy:  "no-cache",
  variables: { },
onCompleted :async(data)=>{
  if(data) {
    dispatch(setAddEditLoader(false))
    nav("/classement/themes")
    window.parent.postMessage({
      info:"redirect_theme",
      value:"list_pagetitle"
    }, "*")
  }
},
  onError : (err)=>{
    dispatch(setAddEditLoader(false))
    const inputName = err.toString().split('\"')?.[1]
    const error = err.toString().split(":")?.[4]
    if(inputName&&error) message.error(`${inputName} ${error}`)
  }
})
const [updateTheme] = useMutation(graphQL_shema().updateTheme,
{
 fetchPolicy:  "no-cache",
  variables: { },
onCompleted :async(data:any)=>{
  if(data) {
    dispatch(setAddEditLoader(false))
    nav("/classement/themes")
    window.parent.postMessage({
      info:"redirect_theme",
      value:"list_pagetitle"
    }, "*")
  }
}
})


const [deleteTheme]= useMutation(graphQL_shema().deleteTheme, {
    fetchPolicy:  "no-cache",
     variables: { },
onCompleted :(data)=>{
  if(data)
{  dispatch(setLoading(false))}
else       dispatch(setOpenSnackBar({open:true,message:"Vous ne pouvez pas supprimer un théme qui a un théme parent"}));},
onError:(err:any)=>{
  dispatch(setLoading(false))
  dispatch(setOpenSnackBar({open:true,message:"Vous ne pouvez pas supprimer un canal qui a un théme parent"}))
}
})

const [getUrlsThemes] =  useMutation(graphQL_shema().getThemesLink, {
    fetchPolicy:  "no-cache",
     variables: { },
onCompleted :(data)=>{
  if(data?.ThemeLinks)
     { 
       dispatch(setListThemesLink(data?.ThemeLinks))}
  
}
})
const [uploadFile] = useMutation(graphQL_shema().uploadFile,{
  fetchPolicy:  "no-cache",
  onCompleted:(data)=>{
    if(data?.UploadToS3?.code === 200){
      dispatch(saveImageUrl(data.UploadToS3?.UrlFile))
    }
  }
})


    return({
        GetListThemes,
        createTheme,
         getChannelConfig,
        deleteTheme,
        getUrlsThemes,
        uploadFile,
        updateTheme,
        GetThemeById
    })
}
