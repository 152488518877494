import { useEffect, useState } from 'react';
import UseActionMenu from "./actionsUserTable";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Hooks } from "../../utils/hooks";
import "../../theme.scss"
import { Datagrid } from "@empreinte/components.datagrid";
import { useTranslation } from "react-i18next";
import {useWindowSize} from '../../../../utils/resizeEvent'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { GridNorows } from "@empreinte/components.grid-norows";
import {useDispatch} from "react-redux";
import { setAddThemeActions} from "../../store/themeActions";

function useDataTableMedia({ columns, dataSource }: any) {
    const { t } = useTranslation()
    const { deletemultipleThemes } = Hooks()
    const [selectionModel, setSelectionModel] = useState<any>([]);
    const [selectedRowKeys,] = useState([]);
    const [selectedRow,] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleRowSelection = (rows: any) => {
        setSelectedRows(rows);
    };
    const dispatch = useDispatch()
    
    const windowSize = useWindowSize()
    const hideColumn = () => {
        return  windowSize < 1200
     };
     const hideColumn3 = () => {
      return  windowSize < 1000
   };
     const hideColumn2 = () => {
         return  windowSize < 420
      };
      const OverviewColumn = ({row}: any) => { return row.row.Logo ?  <img style={{
        width: "100px",
        height: "50px",
        imageRendering: "auto",
        margin: "7px"
      }} src={row?.row?.Logo} alt="" /> : <div>-</div>}
      const StringColumn = ({row,name}: any) => { return row.row[name]? <div>{row.row[name]}</div> : <div>-</div>}
    const [source, setSource] = useState([])

    const handleIconClick = (e:any,y:any) => {
      dispatch(setAddThemeActions({
        addThemeConstraintsDataNameChange: "Order",
        addThemeConstraintsDataValueChange: e
    }));
    dispatch(setAddThemeActions({
      addThemeConstraintsDataNameChange: "asc",
      addThemeConstraintsDataValueChange: y
  }));
  dispatch(setAddThemeActions({
    addThemeConstraintsDataNameChange: "ascChecker",
    addThemeConstraintsDataValueChange: y
}));
      };

    const updatedColumns = [
        {
            headerName: t('classement.Overview'),
            field: 'Logo',
            key: 0,
            sortable:false,
            minWidth:120 ,
            width:120,
            align: 'center',
            flex: 1,
            headerAlign:'center',
            className: "columnFeed",
            hide: hideColumn2(),
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">{t("classement.Overview")}</span>
                </>
              ),
            renderCell: (params:any,row:any) => <OverviewColumn row={params}></OverviewColumn>
        },
        {
            headerName: t('classement.Title'),
            field: 'Title',
            align: 'center',
            sortable:false,
            headerAlign:'center',
            key: 1,
            flex: 1,
            minWidth:105 ,   
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">{t("classement.Title")}</span>
                  <div className="toghetherAllArrows">
                    <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                    <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
                  </div>
                </>
              ),        
            render: (any:any,row:any) => {
                return <div>{ row.Title}</div> 
        }
        },
        {
            headerName: t('classement.parenttheme'),
            field: 'Parent',
            align: 'center',
            sortable:false,
            headerAlign:'center',
            hide: hideColumn3(),
            key: 2,
            flex: 1,
            width:375 ,
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">{t("classement.parenttheme")}</span>
                </>
              ),
            renderCell: (params:any,row:any) => <StringColumn row={params} name={'Parent'}></StringColumn>
            
        },
        {
            headerName: t('classement.canals'),
            field: 'channel',
            align: 'center',
            headerAlign:'center',
            sortable:false,
            key: 3,
            flex: 1,
            width:375 ,
            hide: hideColumn(),
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">{t("classement.canals")}</span>
                </>
              ),
            renderCell: (params:any) => {
        return params.row?.channel ? (
          <div className="canauxThemesAligns">
            {params.row.channel?.map((canaux:any) => (
              <div className="canauxThemesGrid" key={canaux}>{canaux}</div>
            ))}
          </div>
        ) : (
          "--"
        );
      },
        }
        ,
        {
            headerName: 'Actions',
            key: 'action',
            flex: 1,
            headerAlign: 'center',
            minWidth:hideColumn2() ? 80 : 100,
            with:hideColumn2() ? 80 : 150 ,
            sortable: false,
            align: 'center',
            renderHeader: () => (
                <>
                  <span className="colomunHeaderUnique">Actions</span>
                </>
              ),
            renderCell: (action: any, record: any) => {
                return (
                    <UseActionMenu record={action.row} />
                )
            }
        },
    ];
    useEffect(() => {
        const list = dataSource?.content?.map((row: any) => {
            let arrayOfnodes = [];
            arrayOfnodes = ({ ...row.node, Id: row.node?.Id, Parent: row.node?.Parent?.Title, channel: Array.from(row.node.WebtvChannel, ({ Title }) => Title) })
            return arrayOfnodes
        })
        setSource(list)
    }, [dataSource.content]);
    // @ts-ignore
    const DataTable = ({ setCurrentPage, currentPage, setPageSize, pageSize }: any) => (
        <div className="DataTable">
            <DndProvider backend={HTML5Backend}>
                <div style={{
                    height: 700,
                    width: "100%",
                    border: "none",
                }}
                >
                   {source?.length ===0 ? <GridNorows content={t("classement.No matching records found")} /> :
                   <Datagrid
                        rows={source?.length
                            ? source
                            : []}
                        columns={updatedColumns as any}
                        selectionModel={selectionModel}
                        checkboxSelect={(ids:any) => {
                            setSelectionModel(ids)
                            deletemultipleThemes(ids)
                        }}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        totalEntities={dataSource?.filteredElements}
                        EntriesName={`${t("classement.entries")}`}
                        OfPageName={`${t("classement.of")}`}
                        ShowName={`${t("classement.Show")}`}
                        height={"calc(100vh - 185px)"}
                    />
            }
        </div>
        </DndProvider>
        </div>
    );

    return {
        DataTable,
        selectedRow,
        selectedRowKeys,
        selectionModel
    };
}

export default useDataTableMedia;
