import gql from "graphql-tag";

export const graphQL_shema = ()=> {

    const Get_All_Channels = gql`
    mutation($where:ChannelWhereInput $pagination: Pagination){ 
      channels(where:$where pagination:$pagination orderBy:{field:ID direction:DESC} ){
        totalCount 
        edges{node
          {Id 
          Title 
          ChannelConfigId{
           Id 
            IsCdn 
            ReadingProtocol 
            DefaultBitrateMobile 
            DefaultBitrateTablet 
            ShowMbr
            DefaultBitrateComputer}
          Servers{Id Name }
          Language
          Quota
          Language
          is_vbr
          Description
          Uid
          SendPushNotification
       
          
          Upload_Path
          Upload_Url
      
          PermalienVideo
          EmbedVideo
          CreatedAt
          
          
          
          }}}}
    `;
  

  
  const ChannelsMultiDelete = gql`
  mutation($id:[Int]!) {
    DeleteChannel(id:$id)
  }
  `

  const Get_Channel_By_Id = gql`
  mutation($id:Int){
    channel(id:$id){
      Id
      Title
      is_vbr
      EmbedVideo
      PermalienVideo
      ChannelConfigId{
        Id 
        IsCdn 
        ShowMbr
        ReadingProtocol 
        DefaultBitrateMobile 
        DefaultBitrateTablet 
        DefaultBitrateComputer
      }
    Description
      Upload_Url 
      Upload_Path 
      Quota 
      Servers{Id Name}
      SendPushNotification     
      Language
    }
  }`

  

  const Get_Servers = gql`
  mutation{servers{edges{node{Id Name}} }}`


      const createChannel = gql`
      mutation($input: CreateChannelInput!) {
        createChannel(input: $input) {
           Id
        
        }
      }`


const updateChannel = gql`
mutation($input: UpdateChannelInput!) {
  updateChannel(input: $input) {
    Id
    Title 
    PermalienVideo EmbedVideo  Servers{Id} SendPushNotification Upload_Url Upload_Path

  }
}


`

      const createChannelConfig = gql`
      mutation($input: CreateChannelConfigInput!) {
        createChannelConfig(input: $input) {
         
          Id
        
        }
      }`
      const updateChannelConfig = gql`
      mutation($input:UpdateChannelConfigInput!){
        updateChannelConfig(input: $input)
        {Id}
      
      
      
      }`

     
  const getChannelsConfig = gql`
  mutation{channelConfigs{totalCount edges{node{Id IsCdn ReadingProtocol DefaultBitrateComputer }}}}` 


    const deleteChannel = gql`
    mutation($id:[Int]!){
      DeleteChannel(id:$id)
    
    }
    
    `

    const deleteChannelConf = gql `
    mutation($id:Int!) {
      DeleteChannelConfig(id:$id)
    }`

     const getUrlExport = gql`
     mutation($input:GetUrlExportChannelActionInput!) {
      getUrlExportChannelAction(input:$input){UrlExportChannel}
    }`
 

    return ({
        Get_All_Channels,
        createChannelConfig,
        updateChannelConfig,
        updateChannel,
        Get_Servers,
        createChannel,
        getChannelsConfig,
        deleteChannel,
        Get_Channel_By_Id,
        deleteChannelConf,
        getUrlExport,
        ChannelsMultiDelete,
    })

}