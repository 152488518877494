import Theme from "./MenuClassement/theme/theme";
import Canal from "./MenuClassement/canal/canal";
import AddEditCanal from "./MenuClassement/canal/components/AddEdit/addEdit";
import AddEditTheme from "./MenuClassement/theme/components/AddEdit/addEdit";
import { Routes, Route} from "react-router-dom";
import PagenotFound from './MenuClassement/pagenotfound/404notFound'
import "./customFont.css"
function App() {
    return (
        <div className="App">
             <Routes>
        <Route path="/classement/themes" element={<Theme />} />
        <Route path="/classement/channels" element={<Canal />} />
        <Route path="/classement/channel/:id/:action" element={<AddEditCanal />} />
        <Route path="/classement/Theme/:id/:action" element={<AddEditTheme />} />
        <Route path="/classement/*" element={<PagenotFound />} />
        </Routes>
        </div>
    );
}

export default App;
