import { Dispatch } from "redux";
import axios from "axios";
export const setChannels = (channels : any)=> {
    return { type : "SET_CHANNELS" ,
            payload: channels}
}

export function setPaginationPropsTheme(e:any){
    return{
        type:"SET_PaginationPropsTheme",
        payload:e,
    }
}
export function setTitle(e:any) {
    return {
        type:"SET_TITLE",
        payload:e
    }
}
export function setOpenSnackBar(e:any){
    return{
        type:"SET_openSnackBar",
        payload:e,
    }
}
export function setListThemes(e:any){
    return {
        type : "SET_List_Themes",
        payload:e
    }
}
export function setThemesToDelete(e:any){
  
    return {
        type : "SET_Themes_TO_DELETE",
        payload:e
    }
}

export function setAddEditLoader(status:any) {
    return {
        type:"SET_Add_edit",
        payload:status
    }
};

export function SetModalDisplay () {
    return {
        type:"SET_MODAL_DISPLAY",
        payload:true
    }
}

export function setModalDisplayOff() {
    return {
        type:"SET_MODAL_DISPLAY",
        payload:false
    }


}

export function setListThemesLink(e:any){   
    return {
        type : "SET_Links_Themes",
        payload:e
    }
}
export function setAddThemeActions(e:any){
    return{
        type:"SET_AddTheme",
        payload:e,
    }
}

export function setLoading(e:boolean){

    return{
        type:"SET_Loading",
        payload:e,
    }

}
export function setIdFromParams(id:any){
    return{
        type:"SET_ID",
        payload:id
    }
}
export const saveImageUrl = (link:string)=>{
    return{
        type:"SET_IMG_URL",
        payload:link
    }

}