import * as React from 'react';
import {useMutation} from "@apollo/react-hooks";
import {graphQL_shema} from "./graphQL";
import {useDispatch, useSelector} from "react-redux";
 import {setChannelList,setServerList,setOpenSnackBar,setAddCanalActions, setChannelConfig, setEditChannelValue,setUrlExportChannelConf, setLoading, setMultiDeleteData, setSingleDelete, setOnAddEditLoader} from "../store/canalActions";
 import { message } from "antd";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

export const GraphQLFetchData=()=> {
    const {t}= useTranslation();
    const dispatch = useDispatch()
    const nav = useNavigate()

    const Id = useSelector((state:any)=>state.CanalReducer.checkBoxDelete);
    const [GetListChannels,{data:dataGroupsUpdated}]
        = useMutation(graphQL_shema().Get_All_Channels, {
            fetchPolicy:  "no-cache",
             variables: { },
        onCompleted :(data)=>{
             dispatch(setChannelList(data?.channels));
        }
             })
    const [GetListServers]
    = useMutation(graphQL_shema().Get_Servers, {
        fetchPolicy:  "no-cache",
         variables: { },
    onCompleted :(data)=>{
      if(data?.servers)
         dispatch(setServerList(data?.servers.edges));
    }  
})
    const [updateChannel] = useMutation(graphQL_shema().updateChannel,
    {
     fetchPolicy:  "no-cache",
      variables: { },
    onCompleted :async(data)=>{
        if(data) {
            
          
            dispatch(setOnAddEditLoader(false));

          
            message.success({content: t('classement.modifiedSuccessfully'), duration: 2})
            nav("/classement/channels")
        }
   
    },
    onError:()=>{
        message.error({content: 
            `une erreur s'est produite,
            veuillez vérifier vos modifications`, duration: 2})
    }
 }

    )
 
    const [createChannel]= useMutation(graphQL_shema().createChannel, {
        fetchPolicy:  "no-cache",
         variables: { },
    onCompleted :async(data)=>{
        if(data) 
        { 
            dispatch(setOnAddEditLoader(false));
            dispatch(setAddCanalActions({
             addCanalConstraintsDataNameChange: "errorMessage",
             addCanalConstraintsDataValueChange:[{error:"Canal crée avec succées ",openSnack:true,errType:"success",position:{horizontal:'center',vertical: 'top'}}]
         }))  }
         else {
             dispatch(setAddCanalActions({
                 addCanalConstraintsDataNameChange: "errorMessage",
                 addCanalConstraintsDataValueChange:[{error:"Vérifier ",openSnack:true,errType:"error",position:{horizontal:'center',vertical: 'top'}}]
             }))
         }

         nav("/classement/channels")
    },
    onError: (error) =>{
        dispatch(setOnAddEditLoader(false));
      dispatch(setOpenSnackBar({open:true,message:"Une erreur s'est produite,veuillez vérifier vos modifications."}))
    },
   
})

   const [getChannelConfig] = useMutation(graphQL_shema().getChannelsConfig,
   {
    fetchPolicy:  "no-cache",
     variables: { },
onCompleted :(data)=>{
  if(data?.channelConfigs)
  dispatch(setChannelConfig(data?.channelConfigs.edges))
}
}
   
   )

const [ChannelMultiDelete] = useMutation(graphQL_shema().ChannelsMultiDelete,
{
    fetchPolicy:"no-cache",
    variables : {
        id:Id
    },
    onCompleted:(data)=>{
        if(data) {
            dispatch(setMultiDeleteData("data.DeleteChannel"));

            setTimeout(()=>{
                dispatch(setMultiDeleteData([]))
            },1000)
        }
        else { dispatch(setLoading(false));
      dispatch(setOpenSnackBar({open:true,message:"Vous ne pouvez pas supprimer un canal qui a un canal parent"}))
        }
    },
    onError:(err:any)=>{
        dispatch(setLoading(false))
     dispatch(setOpenSnackBar({open:true,message:"Vous ne pouvez pas supprimer un canal qui a un canal parent"}))

    }
})

const [getChannelById] = useMutation(graphQL_shema().Get_Channel_By_Id,
{
 fetchPolicy:  "no-cache",
  variables: { },
onCompleted :(data)=>{
if(data?.channelConfigs)
dispatch(setChannelConfig(data?.channelConfigs.edges))
dispatch(setEditChannelValue(data?.channel))
}
}

)
   
const [createChannelConfig] = useMutation(graphQL_shema().createChannelConfig,
{
 fetchPolicy:  "no-cache",
  variables: { },
onCompleted :(data)=>{
   
   
},
onError:(error)=>{   
},
})
//updateChannelConfig

const [UpdateChannelConfig] = useMutation(graphQL_shema().updateChannelConfig,
   {
    fetchPolicy:  "no-cache",
     variables: { },
onCompleted :(data)=>{
}
}
   
   )


const [deleteChannel]= useMutation(graphQL_shema().deleteChannel, {
    fetchPolicy:  "no-cache",
     variables: { },
onCompleted :(data)=>{
 
    dispatch(setLoading(false))
    if(data) {
        message.success(t("classement.Channel deleted successfully"))

        dispatch(setSingleDelete(["success"]))
        setTimeout(()=>{
        dispatch(setSingleDelete([]))
        },500)
    }
   
     else dispatch(setOpenSnackBar({open:true,message:"Vous ne pouvez pas supprimer un canal qui a un canal parent"}))
},
onError:(err:any)=>{
   dispatch(setOpenSnackBar({open:true,message:"Vous ne pouvez pas supprimer un canal qui a un canal parent"}))
    dispatch(setLoading(false))
   
}
})
const [deleteConfChannel] =  useMutation(graphQL_shema().deleteChannelConf, {
    fetchPolicy:  "no-cache",
     variables: { },
onCompleted :(data)=>{
   
}
})
const [getUrlConfExport] =  useMutation(graphQL_shema().getUrlExport, {
    fetchPolicy:  "no-cache",
     variables: { },
onCompleted :(data)=>{
  if(data?.getUrlExportChannelAction)
     dispatch(setUrlExportChannelConf(data?.getUrlExportChannelAction.UrlExportChannel));  
}
})
    return({
        GetListServers,
        GetListChannels,
        getChannelConfig,
        dataGroupsUpdated,
        getUrlConfExport,
        createChannelConfig,
        createChannel,
        deleteChannel,
        updateChannel,
        deleteConfChannel,
        UpdateChannelConfig,
        getChannelById,
        ChannelMultiDelete
    })
}
