import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";

export const DataCanal = () =>{

    const {t} = useTranslation();
    const channelList = useAppSelector((state) => state.CanalReducer.channelList)
  
    useEffect(()=>{
},[channelList])
    const columns = [
        {
            headerName: 'ID',
            field: 'id',
            key: 0,
            flex:1,
            minWidth:100,
            sortable:false,
        },
        {
            headerName: t('classement.Title'),
            field: 'Title',
            key: 1,
             flex: 1,
            width:300 ,
            sortable:false,
        },
        {
            headerName: t("classement.Language"),
            field: 'Language',
            key: 2,
            flex:1,
            minWidth:160,
            sortable:false,
        },
        {
            headerName: t('classement.Streamingserver'),
            field: 'servers',
            key: 3,
            flex:1,
            minWidth:160 ,
            sortable:false,
        },
        {
            headerName: 'Quotas (Mo)',
            field: 'Quota',
             flex: 1,
            key: 3,
            width:320 
        },

    ];

    const data = {
        totalElements:channelList.totalCount,
        content:channelList.edges  
    }
    const dataContent = data.content   
    return({
        columns,
        data,
        dataContent
    })

}
