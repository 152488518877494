import { useAppSelector } from "../../../redux/hooks";
import '../theme.scss'
export const DataTheme = () =>{
    const themes = useAppSelector((state:any) => state.ThemeReducer.themes)
    const columns :any = [
       
    ];
    const data = {
        totalElements:themes.totalCount,
        filteredElements:themes.filtredCount,
        content:themes.edges        
    }
    const dataContent = data.content

    return({
        columns,
        data,
        dataContent
    })

}